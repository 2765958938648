<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useMainStore } from "../store/main";
import { useMOSAPIStore } from "../store/mos_api";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import MenuEntry from "../classes/DB_Entities/MenuEntry";
import { useRouter } from "vue-router";
import LoginRequest from "../classes/LoginRequest";
import LoginReply from "../classes/LoginReply";
import { useCookies } from "vue3-cookies";
import { useI18n } from "vue-i18n";
import { DateTime } from "luxon";

const { cookies } = useCookies();
const { locale } = useI18n({ useScope: "global" });

const router = useRouter();
const form = ref(new LoginRequest());
const mainStore = useMainStore();
const mosapiStore = useMOSAPIStore();
const pgrestapiStore = usePGRESTAPIStore();
const { showError, showSuccess, showWarning } = useGenericMethodsVariables();

const version = import.meta.env.VITE_APP_VERSION;
let menuOrder: string[] = [];
let menuIcons: string[] = [];
let newMenu: { [key: string]: MenuEntry[] } = {};
let isLoading = ref(false);
let showResetPasswordDialog = ref(false);
let newPassOne = ref("");
let newPassTwo = ref("");
let changingPassword = ref(false);

onMounted(async () => {
  console.log('Mode:', import.meta.env.MODE);
  // console.log(import.meta.env.APP_VERSION);
});

const transformMenuResult = async (menu: Array<MenuEntry>): Promise<void> => {
  let lastMenuName = "";

  for (let i = 0; i < menu.length; ++i) {
    if (menu[i].menu_name !== lastMenuName) {
      lastMenuName = menu[i].menu_name;
      newMenu[lastMenuName] = [];
      menuOrder.push(lastMenuName);
      if (menu[i].menu_icon !== null)
        menuIcons.push(menu[i].menu_icon);
    }

    newMenu[lastMenuName].push({
      menu_name: menu[i].menu_name,
      title: menu[i].title,
      path: menu[i].path,
    } as any);

    // Don't add menu_icon in case of null, to make cookie smaller
    if (menu[i].menu_icon !== null) {
      newMenu[lastMenuName][newMenu[lastMenuName].length - 1].menu_icon = menu[i].menu_icon;
    }
  }
};

const login = async () => {
  isLoading.value = true;

  let reply: LoginReply = await mosapiStore.login(
    form.value.username,
    form.value.password
  );

  if (reply.error) {
    showError(reply.error.message);
    isLoading.value = false;
    return;
  }

  transformMenuResult(reply.screens);

  mainStore.setUser(reply.user);
  mainStore.setMenu(newMenu);
  mainStore.setMenuOrder(menuOrder);
  mainStore.setMenuIcons(menuIcons);
  mosapiStore.setToken(reply.token);
  pgrestapiStore.setToken(reply.token);

  // Use default English language if user has not set a language
  if (reply.user.language === null)
    locale.value = "en";
  else
    locale.value = reply.user.language;

  cookies.set(
    "dataCookie",
    JSON.stringify({
      token: reply.token,
    })
  );

  // console.log(JSON.stringify({ menu: newMenu, }))
  // console.log('menuCookie length:', JSON.stringify({ menu: newMenu, }).length)

  // console.log(Object.keys(newMenu));

  cookies.set('menuListCookie', JSON.stringify(Object.keys(newMenu)));

  Object.keys(newMenu).forEach((key) => {
    cookies.set(
      "menu_" + key,
      JSON.stringify({
        menu: newMenu[key],
      })
    );
  });
  
  // cookies.set(
  //   "menuCookie",
  //   JSON.stringify({
  //     menu: newMenu,
  //   })
  // );

  cookies.set(
    "userCookie",
    JSON.stringify({
      user: reply.user,
    })
  );

  cookies.set(
    "data2Cookie",
    JSON.stringify({
      token: reply.token,
    })
  );

  cookies.set(
    "menuOrderCookie",
    JSON.stringify({
      menuOrder: menuOrder,
    })
  );

  cookies.set(
    "menuIconsCookie",
    JSON.stringify({
      menuIcons: menuIcons,
    })
  );

  isLoading.value = false;

  // Check if user is flagged for password change
  if (reply.user.reset_password_on_login) {
    showResetPasswordDialog.value = true;
    newPassOne.value = "";
    newPassTwo.value = "";
    return;
  }

  let daysWithoutPasswordChange = DateTime.now().diff(
    DateTime.fromISO(reply.user.last_password_change),
    "days"
  ).days;

  // console.log("days:", daysWithoutPasswordChange);

  // Check if user needs to change password after 180 days
  if (daysWithoutPasswordChange >= 180) {
    showResetPasswordDialog.value = true;
    newPassOne.value = "";
    newPassTwo.value = "";
    return;
  }

  //console.log(menuIcons);
  router.push("/dashboard");
};

const checkPassword = () => {
  if (newPassOne.value !== newPassTwo.value) {
    showWarning("Passwords must match");
    return false;
  }

  if (newPassOne.value.length < 8) {
    showError("Password must be at least 8 characters long.");
    return;
  }

  let hasUpperCase = /[A-Z]/.test(newPassOne.value) ? 1 : 0;
  let hasLowerCase = /[a-z]/.test(newPassOne.value) ? 1 : 0;
  let hasNumbers = /\d/.test(newPassOne.value) ? 1 : 0;
  let hasNonalphas = /\W/.test(newPassOne.value) ? 1 : 0;

  if (hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas < 3) {
    showError(
      "Password must contain at least one uppercase letter, one lowercase letter, one digit and one non-alphanumeric character (e.g. !, @, #, $)",
      6500
    );
    return false;
  }

  return true;
};

const changePass = async () => {
  if (!checkPassword()) return;

  isLoading.value = true;

  let result = await mosapiStore.updatePassword(
    mainStore.loggedUser.id as number,
    newPassOne.value
  );

  isLoading.value = false;

  if (result.error) {
    showError(result.error.message);
    return;
  }

  showResetPasswordDialog.value = false;

  newPassOne.value = "";
  newPassTwo.value = "";

  router.push("/dashboard");
};

const cancelReset = async () => {
  showResetPasswordDialog.value = false;

  form.value.password = "";

  mainStore.unsetUser();
  mosapiStore.unsetToken();
  pgrestapiStore.unsetToken();

  cookies.remove("dataCookie");
  cookies.remove("data2Cookie");
  cookies.remove("menuListCookie");
  cookies.remove("menuCookie");
  cookies.remove("userCookie");
  cookies.remove("menuOrderCookie");
  cookies.remove("menuIconsCookie");

  router.push("/login");
};
</script>

<template>
  <div class="login-container">
    <div class="title-icon">
      <el-col :span="12" class="logo-wrapper"><img src="../assets/LOGO_MIDDLE-OFFICE-RGB.png" /></el-col>
    </div>
    <div class="login-logo1"></div>
    <div class="title-icon2">
    </div>

    <div class="title-disclaimer copyrights-wrapper">
      Copyright &copy; 2024 MOS Platform <b>{{ version }}</b>. All Rights Reserved.
      <a title="Optimos" href="https://www.optimos.gr" target="_blank" rel="noopener">Web Design &amp; development by
        Optimos</a>
    </div>

    <el-card class="login-card" :body-style="{ padding: '0px 20px' }" shadow="hover" v-loading="isLoading">
      <el-form class="login-form" label-width="0px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item>
              <el-input :placeholder="$t('questions.enter_username')" name="login" autocomplete="none"
                v-model="form.username" clearable @keyup.enter="login"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item>
              <el-input placeholder="Password" type="password" name="password" autocomplete="none"
                v-model="form.password" @keyup.enter="login" show-password />
            </el-form-item>
            <el-button class="login-button" type="primary" size="default" color="#048" @click="login">Login</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-dialog v-model="showResetPasswordDialog" title="Reset password" width="50%">
        <span>Please enter a new password</span>
        <template #footer #default="scope">
          <el-form>
            <el-row>
              <el-col :span="24">
                <el-form-item label="New Password">
                  <el-input v-model="newPassOne" type="password" placeholder="Please input password" autocomplete="off"
                    show-password />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <el-form-item label="Confirm Password">
                  <el-input v-model="newPassTwo" type="password" placeholder="Please confirm your password"
                    autocomplete="off" show-password />
                </el-form-item>
              </el-col>
            </el-row>
            <span class="dialog-footer" v-loading="changingPassword">
              <el-button @click="cancelReset">Back to login</el-button>
              <el-button type="primary" @click="changePass">Update</el-button>
            </span>
          </el-form>
        </template>
      </el-dialog>
    </el-card>
  </div>
</template>

<style scoped>

</style>
